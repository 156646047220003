import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

function renderInputComponent(inputProps) {
  const {
    classes, inputRef = () => {
    }, ref, ...other
  } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
      type="search"
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion, query);
  const parts = parse(suggestion, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => (
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          )
        ))}
      </div>
    </MenuItem>
  );
}

class IntegrationAutosuggest extends React.Component {
  constructor(props) {
    super(props);

    const { initialQuery } = this.props;

    this.state = {
      single: initialQuery,
      suggestions: [],
    };
  }

  handleSuggestionsFetchRequested = async ({ value }) => {
    if (value.length < 2) {
      return;
    }

    const { url } = this.props;
    const rawResponse = await fetch(url + value);
    const data = await rawResponse.json();

    this.setState({ suggestions: data });
  };

  handleSuggestionSelected = (event, { suggestionValue }) => {
    const { onChangeQuery } = this.props;
    onChangeQuery(suggestionValue);
  };

  getSuggestionValue = suggestion => suggestion;

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = name => (event, { newValue }) => {
    this.setState({
      [name]: newValue,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { onChangeQuery } = this.props;
    const { single } = this.state;

    onChangeQuery(single);
  };

  render() {
    const { classes } = this.props;
    const { suggestions, single } = this.state;

    const autosuggestProps = {
      renderInputComponent,
      suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion,
      onSuggestionSelected: this.handleSuggestionSelected,
    };

    return (
      <div className={classes.root}>
        <form onSubmit={this.handleSubmit}>
          <Autosuggest
            {...autosuggestProps}
            inputProps={{
              classes,
              placeholder: 'Трек, исполнитель',
              value: single,
              onChange: this.handleChange('single'),
            }}
            theme={{
              container: classes.container,
              suggestionsContainerOpen: classes.suggestionsContainerOpen,
              suggestionsList: classes.suggestionsList,
              suggestion: classes.suggestion,
            }}
            renderSuggestionsContainer={options => (
              <Paper {...options.containerProps} square>
                {options.children}
              </Paper>
            )}
          />
        </form>
      </div>
    );
  }
}

IntegrationAutosuggest.defaultProps = {
  initialQuery: '',
};

IntegrationAutosuggest.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    container: PropTypes.string,
    suggestionsContainerOpen: PropTypes.string,
    suggestion: PropTypes.string,
    suggestionsList: PropTypes.string,
    divider: PropTypes.string,
  }).isRequired,
  onChangeQuery: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  initialQuery: PropTypes.string,
};

export default withStyles(styles)(IntegrationAutosuggest);
