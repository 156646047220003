import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  VideoYouTube, Title, Artists, ContainerBlock,
} from '../../components';
import { API_URL } from '../../config/params';
import { getPreloadedProps, request } from '../../helpers';

class LiveRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      ...getPreloadedProps('live'),
    };
  }

  componentDidMount() {
    const { loaded } = this.state;
    const { id } = this.props;

    if (!loaded) {
      this.loadData(id);
    }
  }

  loadData = async (id) => {
    try {
      this.setState({ loading: true });

      const data = await request(`${API_URL}lives/${id}?seo=1`);
      const { message } = data;
      const found = message !== 'Not Found';

      this.setState({
        data: { ...data },
        loading: false,
        loaded: true,
        found,
      });

      document.title = found ? data.seo.title : '404';
    } catch (e) {
      console.log(e.message);
    }
  };

  render() {
    const {
      loading, loaded, data, found,
    } = this.state;
    let artists = '';
    let title = '';

    if (loaded && found) {
      data.artists.forEach((item, index) => {
        if (index > 0) {
          artists += ', ';
        }

        artists += item.name;
      });

      title = `${artists} — ${data.title} [${data.releaseDate}]`;
    }

    return (
      <ContainerBlock loading={loading} loaded={loaded} found={found}>
        <Title tag="h1">{title}</Title>
        <VideoYouTube source={data.source} title={title} />
        <Artists items={data.artists} />
      </ContainerBlock>
    );
  }
}

LiveRoute.propTypes = {
  id: PropTypes.string.isRequired,
};

const LiveRouteWrapper = (props) => {
  const { match: { params: { id } } } = props;
  return <LiveRoute key={id} id={id} />;
};

LiveRouteWrapper.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default LiveRouteWrapper;
