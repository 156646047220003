import pink from '@material-ui/core/colors/pink';

export default theme => ({
  root: {
    paddingTop: theme.spacing(0.25),
  },
  block: {
    ...theme.mixins.toolbar,
  },
  borderTop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: theme.spacing(0.25),
    background: pink[100],
  },
  bar: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    paddingTop: theme.spacing(0.25),
    zIndex: theme.zIndex.drawer + 2,
    [theme.breakpoints.up('lg')]: {
      left: 240,
    },
  },
  buttonPrev: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  buttonNext: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  buttons: {
    display: 'flex',
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(1),
    },
  },
  image: {
    display: 'none',
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  repeat: {
    display: 'flex',
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: theme.spacing(0.25),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  progressSlider: {
    zIndex: theme.zIndex.drawer + 3,
    position: 'absolute',
    top: -25,
    left: 0,
    right: 0,
    touchAction: 'none',
    fontSize: 0,
    flexShrink: 0,
    cursor: 'pointer',
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      height: theme.spacing(0.25),
      background: '#fff',
      top: 23,
      left: 0,
      right: 0,
    },
  },
  buttonsDesktop: {
    marginLeft: 'auto',
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  buttonsMobile: {
    marginLeft: 'auto',
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  time: {
    ...theme.typography.caption,
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      marginRight: theme.spacing(2.5),
    },
  },
  buttonPopup: {
    marginLeft: 'auto',
    marginRight: -theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
});
