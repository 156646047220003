function formatTime(duration) {
  const number = +duration;

  if (!Number.isFinite(number) || number < 1) {
    return '00:00';
  }

  const num = Math.round(number);

  let m = Math.floor(num / 60);
  let s = parseInt(num - m * 60, 10);

  if (s < 10) {
    s = `0${s}`;
  }

  if (m < 10) {
    m = `0${m}`;
  }

  return `${m}:${s}`;
}

export default formatTime;
