import {
  onPlaying, onPause, onTimeUpdate, onEnded,
} from './listeners';

const audio = (typeof Audio !== 'undefined') ? new Audio() : {};

if (typeof audio.addEventListener !== 'undefined') {
  audio.addEventListener('ended', onEnded);
  audio.addEventListener('timeupdate', onTimeUpdate);
  audio.onplaying = onPlaying;
  audio.onpause = onPause;
}

export default audio;
