import React from 'react';
import PropTypes from 'prop-types';
import { Waiting, NotFound } from '../..';

function WaitingBlock(props) {
  const {
    loading, loaded, children, found,
  } = props;

  return (
    <>
      {loading && <Waiting />}
      {(!loading && loaded && found) && (
        <>
          {children}
        </>
      )}
      {(!loading && loaded && !found) && (
        <NotFound />
      )}
    </>
  );
}

WaitingBlock.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  found: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default WaitingBlock;
