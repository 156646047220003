import { RADIO_TIMEOUT } from '../../config/params';
import updateRadioInfo from './updateRadioInfo';

let intervalId = null;

export function setRadioInfo() {
  updateRadioInfo();

  if (!intervalId) {
    intervalId = setInterval(updateRadioInfo, RADIO_TIMEOUT);
  }
}

export function unsetRadioInfo() {
  if (intervalId) {
    clearInterval(intervalId);
    intervalId = null;
  }
}
