export default theme => ({
  root: {
    padding: [[0, theme.spacing(2)]],
  },
  fixed: {
    [theme.breakpoints.up('sm')]: {
      width: 570,
      margin: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      width: 750,
      margin: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      width: 992,
      margin: 'auto',
    },
    [theme.breakpoints.up('xl')]: {
      width: 1170,
      margin: 'auto',
    },
  },
});
