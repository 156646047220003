import store from '../../store/store';
import { updateBuffer, updateDuration, updateTime } from '../../store/actionCreators/player';

export default function onTimeUpdate() {
  const {
    player: {
      buffer: storeBuffer, currentTime: storeCurrentTime, duration: storeDuration, isRadio,
    },
  } = store.getState();

  if (isRadio) {
    return;
  }

  const { duration } = this;
  const currentTime = Math.round(this.currentTime);
  const progress = Math.round((currentTime * 100) / duration);
  let buffer = 0;

  if (this.buffered.length) {
    const bufferedEnd = this.buffered.end(this.buffered.length - 1);

    if (duration > 0) {
      buffer = (bufferedEnd / duration) * 100;

      if (buffer > 70) {
        buffer = 100;
      }
    }
  }

  if (storeCurrentTime !== currentTime) {
    store.dispatch(updateTime(currentTime, progress));
  }

  if (storeBuffer !== buffer) {
    store.dispatch(updateBuffer(buffer));
  }

  if (storeDuration !== duration) {
    store.dispatch(updateDuration(duration));
  }
}
