export default theme => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  image: {
    width: 180,
    marginBottom: theme.spacing(2),
    '& img': {
      display: 'block',
      width: '100%',
      height: 'auto',
      borderRadius: theme.shape.borderRadius,
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
    },
  },
  caption: {
    marginBottom: theme.spacing(2),
  },
  block: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(2),
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
});
