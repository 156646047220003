import store from '../../store/store';
import { changeTrack, play, pause } from '../../store/actionCreators/player';
import { REPEAT, REPEAT_ONE } from '../../config/player';

export default function onEnded() {
  const {
    player: {
      index, repeat, playlist, isRadio,
    },
  } = store.getState();

  if (isRadio) {
    return;
  }

  store.dispatch(pause());

  if (repeat === REPEAT_ONE) {
    store.dispatch(play());
    return;
  }

  let nextIndex = -1;

  if (playlist[index + 1]) {
    nextIndex = index + 1;
  } else if (repeat === REPEAT) {
    nextIndex = 0;
  }

  if (nextIndex !== -1) {
    store.dispatch(changeTrack(nextIndex));
    store.dispatch(play());
  }
}
