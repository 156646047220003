import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { NavLink, Route } from 'react-router-dom';
import styles from './styles';

function NavList(props) {
  const { classes, links } = props;

  return (
    <List className={classes.list}>
      {links.map(item => (
        <Route
          key={item.path}
          path={item.path}
          exact={item.exact}
          children={({ match }) => (
            <NavLink to={item.path} exact={item.exact} className={classes.navLink}>
              <ListItem button className={classes.listItem} selected={!!match} tabIndex="-1">
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
            </NavLink>
          )}
        />
      ))}
    </List>
  );
}

NavList.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    listItem: PropTypes.string,
    navLink: PropTypes.string,
  }).isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.element,
    path: PropTypes.string,
    exact: PropTypes.bool,
  })).isRequired,
};

export default withStyles(styles)(NavList);
