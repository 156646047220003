import store from '../../store/store';
import { changeTrack, play, pause } from '../../store/actionCreators/player';

export default function handleSkipNext(event) {
  event.stopPropagation();

  const { player: { isPlay, playlist, index } } = store.getState();

  if (isPlay) {
    store.dispatch(pause());
  }

  const nextIndex = playlist[index + 1] ? index + 1 : 0;

  store.dispatch(changeTrack(nextIndex));
  store.dispatch(play());
}
