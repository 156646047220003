import { LOAD_MAIN } from '../actionTypes';
import { API_URL } from '../../config/params';

function loadMain() {
  return {
    type: LOAD_MAIN,
    callAPI: `${API_URL}main?seo=1`,
  };
}

export default loadMain;
