export const API_URL = (process.env.REACT_APP_FLAG === 'production')
  ? 'https://api.chmedia.ru/v1/' : 'https://staging.api.chmedia.ru/v1/';
export const RADIO_TIMEOUT = 15000;
export const DEFAULT_TRACK_IMAGE = 'https://cdn.chmedia.ru/common/track.png';
export const RADIO_LINK = 'https://myradio24.org/7293';
export const RADIO_INFO = 'https://myradio24.com/users/7293/status.json';
export const VOLUME_KEY = 'volume';
export const LINKS = {
  artist: '/artists/',
  collection: '/collections/',
  clip: '/clips/',
  live: '/live/',
};
