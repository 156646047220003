import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Title, Status } from '../../index';

function NotFound() {
  return (
    <Status code={404}>
      <Title>Not Found (#404)</Title>
      <Typography variant="body2">Страница не найдена.</Typography>
    </Status>
  );
}

export default NotFound;
