import { LOAD_COLLECTIONS, START, SUCCESS } from '../actionTypes';

const defaultState = {
  loading: false,
  loaded: false,
  items: [],
  seo: {},
};

export default function (state = defaultState, action) {
  const { type } = action;

  switch (type) {
    case LOAD_COLLECTIONS + START:
      return { ...state, loading: true };
    case LOAD_COLLECTIONS + SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: action.data.items,
        seo: action.data.seo,
      };
    default:
      return state;
  }
}
