import store from '../../store/store';
import { play } from '../../store/actionCreators/player';

export default () => {
  const { player } = store.getState();

  if (!player.isPlay) {
    store.dispatch(play());
  }
};
