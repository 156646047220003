import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { hidePopup } from '../../../store/actionCreators/player';
import styles from './styles';

class TrackMenu extends Component {
  state = {
    anchorEl: null,
  };

  handleOpen = (event) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = id => (event) => {
    event.stopPropagation();

    const { isPopup, hidePopupDispatch, history } = this.props;

    this.setState({ anchorEl: null });

    if (id) {
      if (isPopup) {
        hidePopupDispatch();
      }

      history.push(`/artists/${id}/`);
    }
  };

  render() {
    const { artists, classes } = this.props;
    const { anchorEl } = this.state;

    return (
      <div className={classes.root}>
        <IconButton
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleOpen}
          className={classes.button}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose(0)}
          disableAutoFocusItem
        >
          {
            artists.map(item => (
              <MenuItem onClick={this.handleClose(item.id)} key={item.id}>
                {item.name}
              </MenuItem>
            ))
          }
        </Menu>
      </div>

    );
  }
}

TrackMenu.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
  artists: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    isFeaturing: PropTypes.number,
  })).isRequired,
  // from router
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  // from store
  isPopup: PropTypes.bool.isRequired,
  hidePopupDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ isPopup: state.player.isPopup });

const mapDispatchToProps = { hidePopupDispatch: hidePopup };

export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps,
)(withRouter(TrackMenu)));
