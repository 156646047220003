export default theme => ({
  root: {
    maxWidth: 716,
    marginBottom: theme.spacing(2),
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    '& iframe': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  image: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
  block: {
    marginBottom: theme.spacing(1),
    overflow: 'hidden',
    cursor: 'pointer',
    position: 'relative',
    '&:hover $buttonPlay': {
      background: '#f00',
    },
    [theme.breakpoints.up('sm')]: {
      borderRadius: theme.shape.borderRadius,
    },
    '&:focus': {
      outline: 'none',
    },
    '&:focus $buttonPlay': {
      background: '#f00',
    },
  },
  content: {
    '&:focus': {
      outline: 'none',
    },
  },
  shadow: {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonPlay: {
    background: 'rgba(33,33,33,0.8)',
    width: theme.spacing(6),
    height: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },
  playIcon: {
    color: '#fff',
  },
  circularProgress: {
    color: '#fff',
  },
});
