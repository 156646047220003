import { combineReducers } from 'redux';
import player from './player';
import main from './main';
import collections from './collections';

export default combineReducers({
  player,
  main,
  collections,
});
