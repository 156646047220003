import React from 'react';
import PropTypes from 'prop-types';
import Videos from '../Videos';
import Pagination from '../../other/Pagination';
import { LINKS } from '../../../config/params';

function Clips(props) {
  const {
    years, pageCount, year, activePage, items,
  } = props;

  const pagesPath = year ? `?year=${year}&pgn=` : LINKS.clip;
  const yearsPath = `${LINKS.clip}?year=`;

  return (
    <>
      {!!years.length && (
        <Pagination pages={years} pageCount={years.length} activePage={year} path={yearsPath} />
      )}
      <Videos items={items} to={LINKS.clip} />
      {!!pageCount && (
        <Pagination pageCount={pageCount} activePage={activePage} path={pagesPath} />
      )}
    </>
  );
}

Clips.defaultProps = {
  years: [],
  activePage: 1,
  year: 0,
  pageCount: 0,
};

Clips.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  activePage: PropTypes.number,
  years: PropTypes.arrayOf(PropTypes.number),
  year: PropTypes.number,
  pageCount: PropTypes.number,
};

export default Clips;
