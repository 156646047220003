import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Tracks, Title, Text, ContainerBlock,
} from '../../components';
import { API_URL } from '../../config/params';
import { getPreloadedProps, request } from '../../helpers';

class CollectionRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      ...getPreloadedProps('collection', {
        collection: {},
        tracks: [],
        seo: {},
      }),
    };
  }

  componentDidMount() {
    const { loaded } = this.state;
    const { id } = this.props;

    if (!loaded) {
      this.loadData(id);
    }
  }

  loadData = async (id) => {
    try {
      this.setState({ loading: true });

      const data = await request(`${API_URL}collections/${id}?seo=1`);
      const { message } = data;
      const found = message !== 'Not Found';

      this.setState({
        data: { ...data },
        loading: false,
        loaded: true,
        found,
      });

      document.title = found ? data.seo.title : '404';
    } catch (e) {
      console.log(e.message);
    }
  };

  render() {
    const {
      loading, loaded, found, data: { collection: { name, id }, tracks, seo },
    } = this.state;

    return (
      <ContainerBlock loading={loading} loaded={loaded} found={found}>
        <Title tag="h1">{name}</Title>
        <Tracks tracks={tracks} compilation={`collection${id}`} columns />
        <Text text={seo.text} />
      </ContainerBlock>
    );
  }
}

CollectionRoute.propTypes = {
  id: PropTypes.string.isRequired,
};

const CollectionRouteWrapper = (props) => {
  const { match: { params: { id } } } = props;
  return <CollectionRoute key={id} id={id} />;
};

CollectionRouteWrapper.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default CollectionRouteWrapper;
