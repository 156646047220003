import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import Track from '../Track';
import {
  changeTrack, pause, play, changePlaylist, disableRadio,
} from '../../../store/actionCreators/player';
import { handlePlayToggle } from '../../../player/handlers';

class Tracks extends Component {
  handleClick = (track, index) => () => {
    const {
      player, compilation, tracks, changeTrackDispatch, pauseDispatch,
      playDispatch, changePlaylistDispatch, disableRadioDispatch,
    } = this.props;

    if (player.isRadio) {
      disableRadioDispatch();
    }

    if (!player.playlist.length) {
      changePlaylistDispatch({ compilation, tracks });
      changeTrackDispatch(index);
      playDispatch();
      return;
    }

    if (player.playlist[player.index].id === track.id) {
      handlePlayToggle();
      return;
    }

    if (player.compilation !== compilation) {
      changePlaylistDispatch({ compilation, tracks });
    }

    pauseDispatch();
    changeTrackDispatch(index);
    playDispatch();
  };

  render() {
    const {
      classes, tracks, player, noImage, columns,
    } = this.props;
    const trackId = player.playlist.length ? player.playlist[player.index].id : 0;
    const { isPlay } = player;

    const firstPartTracks = [];
    const secondPartTracks = [];

    if (columns) {
      const divider = Math.ceil(tracks.length / 2);

      tracks.forEach((item, index) => {
        if (index + 1 <= divider) {
          firstPartTracks.push(item);
        } else {
          secondPartTracks.push(item);
        }
      });

      let index = -1;

      return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.leftColumn}>
              <div className={classes.root}>
                {firstPartTracks.map((item) => {
                  index += 1;
                  return (
                    <Track
                      data={item}
                      key={item.id}
                      onClick={this.handleClick(item, index)}
                      active={trackId === item.id}
                      noImage={noImage}
                      isPlay={isPlay}
                    />
                  );
                })}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.rightColumn}>
              <div className={classes.root}>
                {secondPartTracks.map((item) => {
                  index += 1;
                  return (
                    <Track
                      data={item}
                      key={item.id}
                      onClick={this.handleClick(item, index)}
                      active={trackId === item.id}
                      noImage={noImage}
                      isPlay={isPlay}
                    />
                  );
                })}
              </div>
            </div>
          </Grid>
        </Grid>
      );
    }

    return (
      <div className={classes.root}>
        {tracks.map((item, index) => (
          <Track
            data={item}
            key={item.id}
            onClick={this.handleClick(item, index)}
            active={trackId === item.id}
            noImage={noImage}
            isPlay={isPlay}
          />
        ))}
      </div>
    );
  }
}

Tracks.defaultProps = {
  noImage: false,
  columns: false,
};

Tracks.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    leftColumn: PropTypes.string,
    rightColumn: PropTypes.string,
  }).isRequired,
  tracks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    version: PropTypes.string,
    duration: PropTypes.number,
    artists: PropTypes.array,
  })).isRequired,
  compilation: PropTypes.string.isRequired,
  noImage: PropTypes.bool,
  columns: PropTypes.bool,
  // from store
  player: PropTypes.shape({
    compilation: PropTypes.string,
    playlist: PropTypes.array,
    isPlay: PropTypes.bool,
    isRadio: PropTypes.bool,
  }).isRequired,
  playDispatch: PropTypes.func.isRequired,
  pauseDispatch: PropTypes.func.isRequired,
  changeTrackDispatch: PropTypes.func.isRequired,
  changePlaylistDispatch: PropTypes.func.isRequired,
  disableRadioDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ player: state.player });

const mapDispatchToProps = {
  playDispatch: play,
  pauseDispatch: pause,
  changeTrackDispatch: changeTrack,
  changePlaylistDispatch: changePlaylist,
  disableRadioDispatch: disableRadio,
};

export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps,
)(Tracks));
