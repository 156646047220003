import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import Caption from '../../typography/Caption';
import styles from './styles';

function Radio(props) {
  const {
    classes, track, isPlay, onClick,
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.image}>
        <img src={track.artists[0].image} alt="" />
      </div>
      <div className={classes.block}>
        <div className={classes.caption}>
          <Typography variant="overline" gutterBottom>
            Сейчас играет:
          </Typography>
          <Caption
            title={track.title}
            version={track.version}
            artists={track.artists}
          />
        </div>
        <Fab variant="extended" color="primary" size="medium" onClick={onClick}>
          {
            isPlay
              ? <PauseIcon className={classes.icon} /> : <PlayArrowIcon className={classes.icon} />
          }
          Слушать
        </Fab>
      </div>
    </div>
  );
}

Radio.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    image: PropTypes.string,
    caption: PropTypes.string,
    block: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  track: PropTypes.shape({
    title: PropTypes.string,
    version: PropTypes.string,
    artists: PropTypes.array,
  }).isRequired,
  isPlay: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(Radio);
