import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

function Cover(props) {
  const { classes, title, image } = props;

  return (
    <div className={classes.root}>
      <img src={image} alt={title} className={classes.image} />
    </div>
  );
}

Cover.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default withStyles(styles)(Cover);
