import store from '../../store/store';
import { changeTrack, play, pause } from '../../store/actionCreators/player';

export default function handleSkipPrevious(event) {
  event.stopPropagation();

  const { player: { isPlay, playlist, index } } = store.getState();

  if (isPlay) {
    store.dispatch(pause());
  }

  const nextIndex = playlist[index - 1] ? index - 1 : playlist.length - 1;

  store.dispatch(changeTrack(nextIndex));
  store.dispatch(play());
}
