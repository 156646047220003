import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RadioInfo, WaitingBlock } from '../../../components';
import { enableRadio, play, pause, updateRadioInfo } from '../../../store/actionCreators/player';
import { setRadioInfo, unsetRadioInfo } from '../../../player/helpers/radio';

class RadioInfoWrapper extends Component {
  componentDidMount() {
    const { player: { isRadio } } = this.props;

    if (!isRadio) {
      setRadioInfo();
    }
  }

  componentWillUnmount() {
    const {
      player: { isRadio }, updateRadioInfoDispatch,
    } = this.props;

    if (!isRadio) {
      unsetRadioInfo();
      updateRadioInfoDispatch({});
    }
  }

  handleClick = () => {
    const {
      player: { isRadio, isPlay }, enableRadioDispatch, playDispatch, pauseDispatch,
    } = this.props;

    if (isRadio) {
      if (isPlay) {
        pauseDispatch();
      } else {
        playDispatch();
      }
    } else {
      enableRadioDispatch();
      playDispatch();
    }
  };

  render() {
    const { player: { radioInfo, isPlay, isRadio } } = this.props;

    return (
      <WaitingBlock loading={!radioInfo.id} loaded={!!radioInfo.id} found={!!radioInfo.id}>
        <RadioInfo
          track={radioInfo}
          isPlay={isPlay && isRadio}
          onClick={this.handleClick}
        />
      </WaitingBlock>
    );
  }
}

RadioInfoWrapper.propTypes = {
  // from store
  player: PropTypes.shape({
    isRadio: PropTypes.bool,
    isPlay: PropTypes.bool,
    radioInfo: PropTypes.object,
  }).isRequired,
  enableRadioDispatch: PropTypes.func.isRequired,
  playDispatch: PropTypes.func.isRequired,
  pauseDispatch: PropTypes.func.isRequired,
  updateRadioInfoDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ player: state.player });

const mapDispatchToProps = {
  enableRadioDispatch: enableRadio,
  playDispatch: play,
  pauseDispatch: pause,
  updateRadioInfoDispatch: updateRadioInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(RadioInfoWrapper);
