export default theme => ({
  caption: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  content: {
    '& p': {
      margin: 0,
      marginTop: theme.spacing(1),
    },
    '& b': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& table': {
      marginTop: theme.spacing(1),
      borderCollapse: 'collapse',
    },
    '& th': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& td, & th': {
      padding: 5,
      border: '1px solid #d3d9de',
    },
  },
});
