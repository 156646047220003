export default theme => ({
  root: {
    display: 'block',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover $shadow': {
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
      },
    },
  },
  image: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
  cover: {
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    position: 'relative',
  },
  shadow: {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(103,58,183,0.4)',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonPlay: {
    background: '#fff',
    width: theme.spacing(5),
    height: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },
});
