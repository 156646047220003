import store from '../../store/store';
import { disableRepeat, enableRepeat, enableRepeatOne } from '../../store/actionCreators/player';
import { REPEAT, REPEAT_ONE } from '../../config/player';

export default function handleRepeat(event) {
  event.stopPropagation();

  const { player: { repeat } } = store.getState();

  if (repeat === REPEAT) {
    store.dispatch(enableRepeatOne());
  } else if (repeat === REPEAT_ONE) {
    store.dispatch(disableRepeat());
  } else {
    store.dispatch(enableRepeat());
  }
}
