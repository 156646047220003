import React from 'react';
import { ContainerBlock, NotFound } from '../../components';

function NotFoundRoute() {
  return (
    <ContainerBlock>
      <NotFound />
    </ContainerBlock>
  );
}

export default NotFoundRoute;
