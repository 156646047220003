import {
  PLAY, PAUSE, CHANGE_TRACK, CHANGE_PLAYLIST,
  ENABLE_REPEAT, ENABLE_REPEAT_ONE, DISABLE_REPEAT,
  UPDATE_TIME, UPDATE_BUFFER, UPDATE_DURATION, UPDATE_VOLUME,
  SHOW_POPUP, HIDE_POPUP, ENABLE_RADIO, DISABLE_RADIO, UPDATE_RADIO_INFO,
} from '../actionTypes';

export function showPopup() {
  return {
    type: SHOW_POPUP,
  };
}

export function hidePopup() {
  return {
    type: HIDE_POPUP,
  };
}

export function play() {
  return {
    type: PLAY,
  };
}

export function pause() {
  return {
    type: PAUSE,
  };
}

export function enableRadio() {
  return {
    type: ENABLE_RADIO,
  };
}

export function disableRadio() {
  return {
    type: DISABLE_RADIO,
  };
}

export function updateRadioInfo(radioInfo) {
  return {
    type: UPDATE_RADIO_INFO,
    payload: { radioInfo },
  };
}

export function updateTime(currentTime, progress) {
  return {
    type: UPDATE_TIME,
    payload: { currentTime, progress },
  };
}

export function updateBuffer(buffer) {
  return {
    type: UPDATE_BUFFER,
    payload: { buffer },
  };
}

export function updateDuration(duration) {
  return {
    type: UPDATE_DURATION,
    payload: { duration },
  };
}

export function updateVolume(volume) {
  return {
    type: UPDATE_VOLUME,
    payload: { volume },
  };
}

export function enableRepeat() {
  return {
    type: ENABLE_REPEAT,
  };
}

export function enableRepeatOne() {
  return {
    type: ENABLE_REPEAT_ONE,
  };
}

export function disableRepeat() {
  return {
    type: DISABLE_REPEAT,
  };
}

export function changeTrack(index) {
  return {
    type: CHANGE_TRACK,
    payload: { index },
  };
}

export function changePlaylist({ compilation, tracks }) {
  return {
    type: CHANGE_PLAYLIST,
    payload: { compilation, tracks },
  };
}
