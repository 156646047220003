import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Tracks, Cover, Title, CustomTabs, Clips, Lives, Text, ContainerBlock,
} from '../../components';
import { API_URL } from '../../config/params';
import { getPreloadedProps, request } from '../../helpers';

class ArtistRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      ...getPreloadedProps('artist', {
        artist: {},
        tracks: [],
        clips: [],
        lives: [],
        seo: {},
      }),
      activeTab: 0,
    };
  }

  componentDidMount() {
    const { loaded } = this.state;
    const { id } = this.props;

    if (!loaded) {
      this.loadData(id);
    }
  }

  handleTabs = (event, value) => {
    this.setState({ activeTab: value });
  };


  loadData = async (id) => {
    try {
      this.setState({ loading: true });

      const data = await request(`${API_URL}artists/${id}?seo=1`);
      const { message } = data;
      const found = message !== 'Not Found';

      this.setState({
        data: { ...data },
        loading: false,
        loaded: true,
        found,
      });

      document.title = found ? data.seo.title : '404';
    } catch (e) {
      console.log(e.message);
    }
  };

  render() {
    const {
      loading, loaded, found, activeTab,
      data: {
        artist, tracks, lives, clips, seo,
      },
    } = this.state;

    let tabTracks = -1;
    let tabClips = -1;
    let tabLives = -1;
    let tabIndex = 0;
    const labels = [];

    if (tracks && tracks.length > 0) {
      tabTracks = tabIndex;
      tabIndex += 1;
      labels.push('Песни');
    }

    if (clips && clips.length > 0) {
      tabClips = tabIndex;
      tabIndex += 1;
      labels.push('Клипы');
    }

    if (lives && lives.length > 0) {
      tabLives = tabIndex;
      labels.push('Live');
    }

    return (
      <ContainerBlock loading={loading} loaded={loaded} found={found}>
        <Title tag="h1">{artist.name}</Title>
        <Cover title={artist.name} image={artist.openGraphImage} />
        {labels.length > 1 && (
          <CustomTabs
            value={activeTab}
            onChange={this.handleTabs}
            labels={labels}
          />
        )}
        {activeTab === tabTracks && (
          <Tracks
            tracks={tracks}
            compilation={`artist${artist.id}`}
            noImage
            columns
          />
        )}
        {activeTab === tabClips && <Clips items={clips} />}
        {activeTab === tabLives && <Lives items={lives} />}
        <Text text={seo.text} />
      </ContainerBlock>
    );
  }
}

ArtistRoute.propTypes = {
  id: PropTypes.string.isRequired,
};

const ArtistRouteWrapper = (props) => {
  const { match: { params: { id } } } = props;
  return <ArtistRoute key={id} id={id} />;
};

ArtistRouteWrapper.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default ArtistRouteWrapper;
