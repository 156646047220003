import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import styles from './styles';
import Container from '../Container';
import NavList from '../../other/NavList';

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

class Header extends React.Component {
  state = {
    isOpenDrawer: false,
  };

  toggleDrawer = isOpen => () => {
    this.setState({
      isOpenDrawer: isOpen,
    });
  };

  render() {
    const { classes, links } = this.props;
    const { isOpenDrawer } = this.state;

    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Container fixed={false}>
            <Toolbar disableGutters>
              <IconButton
                className={classes.drawerButton}
                color="inherit"
                aria-label="Menu"
                onClick={this.toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.headerTitle}>
                Чаваш Медиа
              </Typography>
            </Toolbar>
          </Container>
        </AppBar>
        <SwipeableDrawer
          open={isOpenDrawer}
          onOpen={this.toggleDrawer(true)}
          onClose={this.toggleDrawer(false)}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
          >
            <NavList links={links} />
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    headerTitle: PropTypes.string,
    drawerButton: PropTypes.string,
    appBar: PropTypes.string,
  }).isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.element,
    path: PropTypes.string,
    exact: PropTypes.bool,
  })).isRequired,
};

export default withStyles(styles)(Header);
