import { request } from '../../helpers';
import { RADIO_INFO } from '../../config/params';

async function getRadioTrackId() {
  const data = await request(RADIO_INFO);
  const songId = +data.song;

  if (!Number.isFinite(songId) || songId < 1) {
    throw new Error('Incorrect track ID of radio');
  }

  return songId;
}

export default getRadioTrackId;
