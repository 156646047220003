import pink from '@material-ui/core/colors/pink';

export default theme => ({
  root: {
    height: theme.spacing(0.25),
  },
  dashed: {
    animation: 'none',
    background: pink[100],
  },
  linearColorPrimary: {
    backgroundColor: pink[200],
  },
  linearBarColorPrimary: {
    backgroundColor: pink[500],
  },
});
