export default theme => ({
  root: {
    margin: [[0, -theme.spacing(2), theme.spacing(3)]],
    position: 'relative',
    maxWidth: 716,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0,0,0,.1)',
    },
    [theme.breakpoints.up('sm')]: {
      overflow: 'hidden',
      marginLeft: 0,
      marginRight: 0,
      borderRadius: theme.shape.borderRadius,
    },
  },
  image: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
  },
});
