export default theme => ({
  root: {
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      [theme.breakpoints.up('lg')]: {
        background: theme.palette.grey[100],
      },
    },
    '&:hover $shadow': {
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
      },
    },
    '&:focus': {
      outline: 'none',
    },
    '&:focus $content': {
      background: theme.palette.grey[100],
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(8),
    padding: [[0, theme.spacing(2)]],
    '&:focus': {
      outline: 'none',
    },
  },
  active: {
    background: theme.palette.grey[200],
    '&:hover': {
      background: theme.palette.grey[200],
    },
  },
  cover: {
    position: 'relative',
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    flexShrink: 0,
  },
  shadow: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(103,58,183,0.4)',
    color: '#fff',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeShadow: {
    display: 'flex',
  },
  buttonPlay: {
    background: '#fff',
    width: theme.spacing(4),
    height: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },
  right: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  duration: {
    ...theme.typography.caption,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      marginRight: theme.spacing(1),
    },
  },
});
