import { LOAD_COLLECTIONS } from '../actionTypes';
import { API_URL } from '../../config/params';

function loadCollections() {
  return {
    type: LOAD_COLLECTIONS,
    callAPI: `${API_URL}collections?seo=1`,
  };
}

export default loadCollections;
