export default theme => ({
  root: {
    width: 250,
    padding: [[0, theme.spacing(1)]],
  },
  listItem: {
    margin: [[theme.spacing(1), 0]],
  },
  navLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
});
