import { LOAD_MAIN, START, SUCCESS } from '../actionTypes';

const defaultState = {
  loading: false,
  loaded: false,
  newTracks: [],
  newClips: [],
  newLives: [],
  seo: {},
};

export default function (state = defaultState, action) {
  const { type } = action;

  switch (type) {
    case LOAD_MAIN + START:
      return { ...state, loading: true };
    case LOAD_MAIN + SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        newTracks: action.data.newTracks,
        newClips: action.data.newClips,
        newLives: action.data.newLives,
        seo: action.data.seo,
      };
    default:
      return state;
  }
}
