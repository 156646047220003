export default theme => ({
  root: {
    ...theme.mixins.toolbar,
  },
  headerTitle: {
    flexGrow: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  drawerButton: {
    margin: [[0, theme.spacing(1.5), 0, -theme.spacing(1.5)]],
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: 'linear-gradient(to left,#f43457,#1866de)',
  },
});
