import React from 'react';
import PropTypes from 'prop-types';
import Videos from '../Videos';
import Pagination from '../../other/Pagination';
import { LINKS } from '../../../config/params';

function Lives(props) {
  const {
    years, pageCount, year, activePage, items,
  } = props;

  let pagesPath = LINKS.live;
  const yearsPath = `${LINKS.live}?year=`;

  if (year) {
    pagesPath += `?year=${year}&pgn=`;
  }

  return (
    <>
      {!!years && (
        <Pagination
          pages={years}
          pageCount={years.length}
          activePage={year}
          path={yearsPath}
        />
      )}
      <Videos items={items} to={LINKS.live} isReleaseDate />
      {!!pageCount && (
        <Pagination pageCount={pageCount} activePage={activePage} path={pagesPath} />
      )}
    </>
  );
}

Lives.defaultProps = {
  years: [],
  activePage: 1,
  year: 0,
  pageCount: 0,
};

Lives.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  activePage: PropTypes.number,
  years: PropTypes.arrayOf(PropTypes.number),
  year: PropTypes.number,
  pageCount: PropTypes.number,
};

export default Lives;
