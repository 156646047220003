import store from '../../store/store';
import { updateRadioInfo as updateRadioInfoCreator } from '../../store/actionCreators/player';
import getRadioTrackId from './getRadioTrackId';
import getTrack from '../../helpers/getTrack';

async function updateRadioInfo() {
  try {
    const songId = await getRadioTrackId();
    const { player: { radioInfo } } = store.getState();

    if (songId !== radioInfo.id) {
      const track = await getTrack(songId);
      store.dispatch(updateRadioInfoCreator(track));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export default updateRadioInfo;
