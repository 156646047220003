import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import PlayIcon from '@material-ui/icons/PlayArrow';
import Caption from '../../typography/Caption';
import styles from './styles';

function Video(props) {
  const {
    classes, title, source, releaseDate, artists, to,
  } = props;

  return (
    <NavLink className={classes.root} to={to}>
      <div className={classes.cover}>
        <img
          className={classes.image}
          src={`https://img.youtube.com/vi/${source}/mqdefault.jpg`}
          alt=""
        />
        <div className={classes.shadow}>
          <div className={classes.buttonPlay}>
            <PlayIcon color="action" />
          </div>
        </div>
      </div>
      <Caption title={title} artists={artists} />
      {!!releaseDate && (
        <Typography component="div" variant="caption">{releaseDate}</Typography>
      )}
    </NavLink>
  );
}

Video.defaultProps = {
  releaseDate: '',
};

Video.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    image: PropTypes.string,
    cover: PropTypes.string,
    shadow: PropTypes.string,
    buttonPlay: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  artists: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    isFeaturing: PropTypes.number,
  })).isRequired,
  releaseDate: PropTypes.string,
  to: PropTypes.string.isRequired,
};

export default withStyles(styles)(Video);
