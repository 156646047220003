import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import pink from '@material-ui/core/colors/pink';
import { Slider } from 'material-ui-slider';
import IconButton from '@material-ui/core/IconButton';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import styles from './styles';

function VolumeSlider(props) {
  const { volume, onChange, classes } = props;

  return (
    <div className={classes.root}>
      <div className={classes.slider}>
        <Slider value={volume} color={pink[500]} onChange={onChange} />
      </div>
      <IconButton disableRipple className={classes.button}>
        {volume > 0 ? <VolumeUpIcon /> : <VolumeOffIcon />}
      </IconButton>
    </div>
  );
}

VolumeSlider.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    slider: PropTypes.string,
    button: PropTypes.string,
  }).isRequired,
  volume: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(VolumeSlider);
