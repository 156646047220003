import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import Caption from '../../typography/Caption';
import TrackMenu from '../TrackMenu';
import TrackImage from '../TrackImage';
import styles from './styles';
import { formatTime } from '../../../helpers';
import { DEFAULT_TRACK_IMAGE } from '../../../config/params';

function Track(props) {
  const {
    classes, active, onClick, noImage, isPlay,
    data: {
      title, version, duration, artists,
    },
  } = props;

  const shadowClass = active ? `${classes.shadow} ${classes.activeShadow}` : classes.shadow;
  const rootClass = active ? `${classes.root} ${classes.active}` : classes.root;
  const isPlayIcon = active ? !isPlay : true;
  const buttonIcon = isPlayIcon
    ? <PlayIcon color="action" fontSize="small" /> : <PauseIcon color="action" fontSize="small" />;

  return (
    <div
      className={rootClass}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
    >
      <div className={classes.content} tabIndex="-1">
        <div className={classes.cover}>
          <TrackImage src={noImage ? DEFAULT_TRACK_IMAGE : artists[0].image} />
          <div className={shadowClass}>
            <div className={classes.buttonPlay}>
              {
                buttonIcon
              }
            </div>
          </div>
        </div>
        <Caption title={title} version={version} artists={artists} />
        <div className={classes.right}>
          <div className={classes.duration}>
            {formatTime(duration)}
          </div>
          <TrackMenu artists={artists} />
        </div>
      </div>
    </div>
  );
}

Track.defaultProps = {
  noImage: false,
};

Track.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    content: PropTypes.string,
    active: PropTypes.string,
    cover: PropTypes.string,
    shadow: PropTypes.string,
    activeShadow: PropTypes.string,
    buttonPlay: PropTypes.string,
    right: PropTypes.string,
    duration: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    title: PropTypes.string,
    version: PropTypes.string,
    duration: PropTypes.number,
    artists: PropTypes.array,
  }).isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  noImage: PropTypes.bool,
  isPlay: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Track);
