import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import { handleSkipNext } from '../../../player/handlers';

function NextButton(props) {
  const { fontSize } = props;

  return (
    <IconButton onClick={handleSkipNext}>
      {<SkipNextIcon fontSize={fontSize} />}
    </IconButton>
  );
}

NextButton.defaultProps = {
  fontSize: 'default',
};

NextButton.propTypes = {
  fontSize: PropTypes.string,
};

export default NextButton;
