export default theme => ({
  root: {
    overflow: 'hidden',
    ...theme.typography.body2,
  },
  line: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    '& span': {
      ...theme.typography.caption,
    },
  },
  artists: {
    color: theme.palette.text.secondary,
  },
});
