export default theme => ({
  root: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    flex: [[0, 0, `${theme.spacing(6)}px`]],
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    position: 'relative',
  },
  img: {
    display: 'block',
    width: '100%',
    height: 'auto',
  },
});
