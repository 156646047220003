import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

function OpenPopupButton(props) {
  const { onClick } = props;

  return (
    <IconButton onClick={onClick}>
      <KeyboardArrowUpIcon />
    </IconButton>
  );
}

OpenPopupButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default OpenPopupButton;
