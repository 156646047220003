export default theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: [[theme.spacing(3), -theme.spacing(0.5), -theme.spacing(0.5)]],
  },
  top: {
    marginTop: -theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
    margin: theme.spacing(0.5),
  },
  button: {
    minWidth: theme.spacing(4),
  },
});
