import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { handlePlayToggle } from '../../../player/handlers';

function PlayButton(props) {
  const { isPlay } = props;

  return (
    <IconButton onClick={handlePlayToggle}>
      {!isPlay ? <PlayIcon /> : <PauseIcon />}
    </IconButton>
  );
}

PlayButton.propTypes = {
  isPlay: PropTypes.bool.isRequired,
};

export default PlayButton;
