import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import NavList from '../../other/NavList';
import styles from './styles';

function Sidebar(props) {
  const { classes, links } = props;

  return (
    <Drawer className={classes.root} variant="permanent" classes={{ paper: classes.drawerPaper }}>
      <div className={classes.toolbar} />
      <NavList links={links} />
    </Drawer>
  );
}

Sidebar.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    drawerPaper: PropTypes.string,
    toolbar: PropTypes.string,
  }).isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.element,
    path: PropTypes.string,
    exact: PropTypes.bool,
  })).isRequired,
};

export default withStyles(styles)(Sidebar);
