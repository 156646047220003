export default theme => ({
  root: {
    margin: [[-theme.spacing(1), -theme.spacing(2)]],
  },
  leftColumn: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(1),
    },
  },
  rightColumn: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(1),
    },
  },
});
