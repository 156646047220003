import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {
  Title,
  SearchInput,
  Artists,
  Tracks,
  ContainerBlock,
  WaitingBlock,
  Clips,
  Lives,
  CustomDivider,
  Text,
} from '../../components';
import { API_URL } from '../../config/params';
import { getPreloadedProps, request } from '../../helpers';

const searchUrl = `${API_URL}search/?query=`;

class SearchRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      ...getPreloadedProps('search', {
        artists: [],
        tracks: [],
        clips: [],
        lives: [],
        seo: {},
        message: '',
      }),
    };
  }

  componentDidMount() {
    const { loaded } = this.state;

    if (!loaded) {
      const { query } = this.props;
      this.loadData(query);
    }
  }

  loadData = async (query) => {
    try {
      this.setState({ loading: true, loaded: false });

      const data = await request(`${API_URL}search/detail/?query=${query}&seo=1`);

      this.setState({
        data: { ...data },
        loading: false,
        loaded: true,
      });

      document.title = data.seo.title;
    } catch (e) {
      this.setState({
        loading: false,
        loaded: false,
      });
    }
  };

  handleChangeQuery = (query) => {
    const { history } = this.props;

    history.push(`/search/?query=${query}`);
  };

  render() {
    const {
      loading, loaded,
      data: {
        artists, tracks, clips, lives, message,
      },
    } = this.state;
    const { query } = this.props;

    return (
      <ContainerBlock loaded={loaded} loading={loading}>
        <Title>Поиск</Title>
        <SearchInput
          onChangeQuery={this.handleChangeQuery}
          url={searchUrl}
          initialQuery={query}
        />
        <WaitingBlock loading={loading} loaded={loaded} found={loaded}>
          {!!artists.length && (
            <>
              <CustomDivider />
              <Artists items={artists} />
            </>
          )}
          {!!tracks.length && (
            <>
              <CustomDivider />
              <Tracks tracks={tracks} compilation={`search: ${query}`} columns />
            </>
          )}
          {!!clips.length && (
            <>
              <CustomDivider />
              <Clips items={clips} />
            </>
          )}
          {!!lives.length && (
            <>
              <CustomDivider />
              <Lives items={lives} />
            </>
          )}
          <Text text={message} />
        </WaitingBlock>
      </ContainerBlock>
    );
  }
}

SearchRoute.defaultProps = {
  query: '',
};

SearchRoute.propTypes = {
  query: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const SearchRouteWrapper = (props) => {
  const { location: { search } } = props;
  const params = queryString.parse(search);
  const query = params.query ? params.query : '';
  return <SearchRoute key={query} query={query} {...props} />;
};

SearchRouteWrapper.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default SearchRouteWrapper;
