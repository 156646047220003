import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import styles from './styles';

function Album(props) {
  const {
    classes, name, image, to,
  } = props;

  return (
    <NavLink className={classes.root} to={to}>
      <div className={classes.image}>
        <img src={image} alt={name} />
      </div>
      <Typography component="div" variant="body2">{name}</Typography>
    </NavLink>
  );
}

Album.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default withStyles(styles)(Album);
