import isLocalStorage from './isLocalStorage';

function getPageNumber() {
  if (isLocalStorage()) {
    return window.localStorage.getItem('pageNumber') || 1;
  }

  return 1;
}

export default getPageNumber;
