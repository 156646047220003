import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { connect } from 'react-redux';
import { showPopup, updateVolume } from '../../../store/actionCreators/player';
import { formatTime } from '../../../helpers';
import {
  Container, Caption, TrackMenu, TrackImage,
} from '../../index';
import {
  Progress, RepeatButton, PrevButton, NextButton, PlayButton,
  OpenPopupButton, ProgressSlider, VolumeSlider,
} from '../../player';
import styles from './styles';

function BottomPlayer(props) {
  const {
    classes, playlist, index, isPlay, progress, volume, showPopupDispatch,
    updateVolumeDispatch, repeat, duration, currentTime, isRadio, radioInfo,
  } = props;

  if (!playlist.length && !(isRadio && radioInfo.id)) {
    return <></>;
  }

  let title = '';
  let artists = [];
  let version = '';

  const formatCurrentTime = formatTime(currentTime);
  const formatDuration = formatTime(duration);

  if (isRadio) {
    ({ title, artists, version } = radioInfo);
  } else {
    ({ title, artists, version } = playlist[index]);
  }

  return (
    <div className={classes.root}>
      <div className={classes.block}>
        <div className={classes.bar}>
          {!isRadio && (
            <>
              <div className={classes.progress}>
                <Progress value={progress} />
              </div>
              <div className={classes.progressSlider}>
                <ProgressSlider progress={progress} />
              </div>
            </>
          )}
          {isRadio && (
            <div className={classes.borderTop} />
          )}
          <Container fixed={false}>
            <Toolbar disableGutters>
              <div className={classes.buttons}>
                {!isRadio && (
                  <div className={classes.buttonPrev}>
                    <PrevButton />
                  </div>
                )}
                <PlayButton isPlay={isPlay} />
                {!isRadio && (
                  <div className={classes.buttonNext}>
                    <NextButton />
                  </div>
                )}
              </div>
              {!isRadio && (
                <div className={classes.time}>
                  <span>{formatCurrentTime}</span>
                  {' / '}
                  <span>{formatDuration}</span>
                </div>
              )}
              <div className={classes.image}>
                <TrackImage src={artists[0].image} alt={artists[0].name} />
              </div>
              <Caption title={title} artists={artists} version={version} />
              <div className={classes.buttonsDesktop}>
                <div className={classes.volume}>
                  <VolumeSlider volume={volume} onChange={updateVolumeDispatch} />
                </div>
                {!isRadio && (
                  <div className={classes.repeat}>
                    <RepeatButton repeat={repeat} />
                  </div>
                )}
                <TrackMenu artists={artists} />
              </div>
              <div className={classes.buttonsMobile}>
                {isRadio && (
                  <TrackMenu artists={artists} />
                )}
                {!isRadio && (
                  <div className={classes.buttonPopup}>
                    <OpenPopupButton onClick={showPopupDispatch} />
                  </div>
                )}
              </div>
            </Toolbar>
          </Container>
        </div>
      </div>
    </div>
  );
}

BottomPlayer.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    block: PropTypes.string,
    borderTop: PropTypes.string,
    bar: PropTypes.string,
    buttonPrev: PropTypes.string,
    buttonNext: PropTypes.string,
    buttons: PropTypes.string,
    image: PropTypes.string,
    repeat: PropTypes.string,
    progress: PropTypes.string,
    progressSlider: PropTypes.string,
    buttonsDesktop: PropTypes.string,
    buttonsMobile: PropTypes.string,
    time: PropTypes.string,
    buttonPopup: PropTypes.string,
  }).isRequired,
  // from store
  isPlay: PropTypes.bool.isRequired,
  isRadio: PropTypes.bool.isRequired,
  repeat: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  currentTime: PropTypes.number.isRequired,
  radioInfo: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    version: PropTypes.string,
    artists: PropTypes.array,
  }).isRequired,
  playlist: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    version: PropTypes.string,
    artists: PropTypes.array,
  })).isRequired,
  index: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  volume: PropTypes.number.isRequired,
  showPopupDispatch: PropTypes.func.isRequired,
  updateVolumeDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ ...state.player });

const mapDispatchToProps = {
  showPopupDispatch: showPopup,
  updateVolumeDispatch: updateVolume,
};

export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps,
)(BottomPlayer));
