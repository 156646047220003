import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import RepeatIcon from '@material-ui/icons/Repeat';
import RepeatOneIcon from '@material-ui/icons/RepeatOne';
import { handleRepeat } from '../../../player/handlers';
import { REPEAT, REPEAT_ONE } from '../../../config/player';

function RepeatButton(props) {
  const { repeat } = props;
  const repeatColor = repeat === REPEAT || repeat === REPEAT_ONE ? 'secondary' : 'default';

  return (
    <IconButton onClick={handleRepeat} color={repeatColor}>
      {repeat === REPEAT_ONE ? <RepeatOneIcon /> : <RepeatIcon />}
    </IconButton>
  );
}

RepeatButton.propTypes = {
  repeat: PropTypes.string.isRequired,
};

export default RepeatButton;
