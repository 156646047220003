import React from 'react';
import PropTypes from 'prop-types';
import pink from '@material-ui/core/colors/pink';
import { Slider } from 'material-ui-slider';
import { handleCurrentTime } from '../../../player/handlers';

function ProgressSlider(props) {
  const { progress } = props;

  return (
    <Slider color={pink[500]} value={progress} onChangeComplete={handleCurrentTime} />
  );
}

ProgressSlider.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default ProgressSlider;
