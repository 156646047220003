import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

function CustomDivider(props) {
  const { classes } = props;

  return (
    <div className={classes.root} />
  );
}

CustomDivider.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(CustomDivider);
