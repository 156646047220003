import {
  PLAY, PAUSE, CHANGE_TRACK, HIDE_POPUP,
  SHOW_POPUP, UPDATE_VOLUME, DISABLE_RADIO,
} from '../actionTypes';
import audio from '../../player/audio';
import setVolume from '../../player/helpers/setVolume';
import { unsetRadioInfo } from '../../player/helpers/radio';
import { RADIO_LINK } from '../../config/params';

export default store => next => (action) => {
  const { type } = action;
  const state = store.getState();

  if (type === SHOW_POPUP) {
    if (document.body.clientWidth < 992) {
      document.body.classList.add('overflowHidden');
    } else {
      return;
    }
  }

  if (type === HIDE_POPUP) {
    document.body.classList.remove('overflowHidden');
  }

  if (type === CHANGE_TRACK) {
    audio.src = state.player.playlist[action.payload.index].source;
  }

  if (type === UPDATE_VOLUME) {
    setVolume(action.payload.volume);
  }

  if (type === PLAY) {
    if (window.youtubeEvent) {
      window.youtubeEvent.pauseVideo();
    }

    if (state.player.isRadio) {
      audio.src = RADIO_LINK;
    }

    audio.play();
  }

  if (type === PAUSE) {
    audio.pause();

    if (state.player.isRadio) {
      audio.src = null;
    }
  }

  if (type === DISABLE_RADIO) {
    unsetRadioInfo();
  }

  next(action);
};
