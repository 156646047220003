import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

function Container(props) {
  const { classes, fixed, children } = props;
  const className = fixed ? `${classes.root} ${classes.fixed}` : classes.root;

  return (
    <div className={className}>{children}</div>
  );
}

Container.defaultProps = {
  fixed: true,
};

Container.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    fixed: PropTypes.string,
  }).isRequired,
  fixed: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

export default withStyles(styles)(Container);
