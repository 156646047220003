import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

function Caption(props) {
  const {
    classes, title, artists, ellipsis, version,
  } = props;
  const ellipsisClass = ellipsis ? classes.line : '';
  const firstArtists = [];
  const secondArtists = [];

  artists.forEach((artist) => {
    const { isFeaturing } = artist;

    if (!isFeaturing) {
      firstArtists.push(artist.name);
    } else {
      secondArtists.push(artist.name);
    }
  });

  return (
    <div className={classes.root}>
      <div className={`${ellipsisClass} ${classes.title}`}>
        {title}
        {version && <span>{` (${version})`}</span>}
      </div>
      <div className={`${ellipsisClass} ${classes.artists}`}>
        {firstArtists.join(', ')}
        {!!secondArtists.length && <span>{` feat. ${secondArtists.join(', ')}`}</span>}
      </div>
    </div>
  );
}

Caption.defaultProps = {
  ellipsis: true,
  version: '',
};

Caption.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    line: PropTypes.string,
    title: PropTypes.string,
    artists: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  version: PropTypes.string,
  artists: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    isFeaturing: PropTypes.number,
  })).isRequired,
  ellipsis: PropTypes.bool,
};

export default withStyles(styles)(Caption);
