import isClient from './isClient';

function getPreloadedProps(key, defaultProps = {}) {
  if (global.preloadedProps[key]) {
    const data = {
      ...defaultProps,
      ...global.preloadedProps[key],
    };
    const { message } = data;
    const found = message !== 'Not Found';

    if (isClient()) {
      delete global.preloadedProps[key];
    }

    return {
      loaded: true,
      found,
      data,
    };
  }

  return { data: { ...defaultProps }, loaded: false };
}

export default getPreloadedProps;
