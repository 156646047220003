export default theme => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  '@global': {
    '.overflowHidden': {
      overflow: 'hidden',
    },
  },
});
