export const PLAY = 'PLAY';
export const PAUSE = 'PAUSE';

export const ENABLE_RADIO = 'ENABLE_RADIO';
export const DISABLE_RADIO = 'DISABLE_RADIO';
export const UPDATE_RADIO_INFO = 'UPDATE_RADIO_INFO';

export const CHANGE_TRACK = 'CHANGE_TRACK';
export const CHANGE_PLAYLIST = 'CHANGE_PLAYLIST';

export const ENABLE_REPEAT = 'ENABLE_REPEAT';
export const ENABLE_REPEAT_ONE = 'ENABLE_REPEAT_ONE';
export const DISABLE_REPEAT = 'DISABLE_REPEAT';

export const UPDATE_TIME = 'UPDATE_TIME';
export const UPDATE_BUFFER = 'UPDATE_BUFFER';
export const UPDATE_DURATION = 'UPDATE_DURATION';
export const UPDATE_VOLUME = 'UPDATE_VOLUME';

export const SHOW_POPUP = 'SHOW_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';

export const LOAD_MAIN = 'LOAD_MAIN';
export const LOAD_COLLECTIONS = 'LOAD_COLLECTIONS';

export const START = '_START';
export const SUCCESS = '_SUCCESS';
export const FAIL = '_FAIL';
