import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import MusicVideoIcon from '@material-ui/icons/MusicVideo';
import VideocamIcon from '@material-ui/icons/Videocam';
import RadioIcon from '@material-ui/icons/Radio';
import SearchIcon from '@material-ui/icons/Search';

export default [
  {
    title: 'Главная',
    icon: <HomeIcon />,
    path: '/',
    exact: true,
  },
  {
    title: 'Исполнители',
    icon: <PeopleIcon />,
    path: '/artists/',
    exact: false,
  },
  {
    title: 'Сборники',
    icon: <LibraryMusicIcon />,
    path: '/collections/',
    exact: false,
  },
  {
    title: 'Клипы',
    icon: <MusicVideoIcon />,
    path: '/clips/',
    exact: false,
  },
  {
    title: 'Live',
    icon: <VideocamIcon />,
    path: '/live/',
    exact: false,
  },
  {
    title: 'Радио',
    icon: <RadioIcon />,
    path: '/radio/',
    exact: false,
  },
  {
    title: 'Поиск',
    icon: <SearchIcon />,
    path: '/search/',
    exact: false,
  },
];
