import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import loadMain from '../../store/actionCreators/main';
import {
  Tracks, Title, CustomDivider, Clips, Lives, Text, ContainerBlock,
} from '../../components';

class MainRoute extends Component {
  componentDidMount() {
    const { loaded, loadMainDispatch, seo } = this.props;

    if (!loaded) {
      loadMainDispatch();
    }

    if (seo.title) {
      global.document.title = seo.title;
    }
  }

  render() {
    const {
      loading, loaded, newTracks, newClips, newLives, seo,
    } = this.props;

    return (
      <ContainerBlock loading={loading} loaded={loaded}>
        <Title>Новинки</Title>
        <Tracks tracks={newTracks} compilation="new" columns />
        <CustomDivider />
        <Title>Клипы</Title>
        <Clips items={newClips} />
        <CustomDivider />
        <Title>Live</Title>
        <Lives items={newLives} />
        <Text caption={seo.caption} text={seo.text} />
      </ContainerBlock>
    );
  }
}

MainRoute.propTypes = {
  // from store
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  newTracks: PropTypes.arrayOf(PropTypes.object).isRequired,
  newClips: PropTypes.arrayOf(PropTypes.object).isRequired,
  newLives: PropTypes.arrayOf(PropTypes.object).isRequired,
  seo: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  loadMainDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ ...state.main });

const mapDispatchToProps = { loadMainDispatch: loadMain };

export default connect(mapStateToProps, mapDispatchToProps)(MainRoute);
