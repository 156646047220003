import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Video from '../Video';

function Videos(props) {
  const { items, isReleaseDate, to } = props;

  return (
    <Grid container spacing={2}>
      {items.map(item => (
        <Grid item xs={6} sm={4} md={3} key={item.id}>
          <Video
            title={item.title}
            source={item.source}
            artists={item.artists}
            releaseDate={isReleaseDate ? item.releaseDate : ''}
            to={`${to}${item.id}/`}
          />
        </Grid>
      ))}
    </Grid>
  );
}

Videos.defaultProps = {
  isReleaseDate: false,
};

Videos.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    source: PropTypes.string,
    artists: PropTypes.array,
    releaseDate: PropTypes.string,
  })).isRequired,
  isReleaseDate: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

export default Videos;
