import store from '../../store/store';
import { pause } from '../../store/actionCreators/player';

export default () => {
  const { player } = store.getState();

  if (player.isPlay) {
    store.dispatch(pause());
  }
};
