import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  MainRoute, ArtistsRoute, ArtistRoute, CollectionsRoute, CollectionRoute, LivesRoute,
  LiveRoute, ClipsRoute, ClipRoute, RadioRoute, SearchRoute, NotFoundRoute,
} from './routes';
import {
  Wrapper, Header, Sidebar, Content, BottomPlayer, PopupPlayer,
} from './components';
import links from './config/nav';

function App() {
  return (
    <Wrapper>
      <Header links={links} />
      <Sidebar links={links} />
      <Content>
        <Switch>
          <Route path="/" exact component={MainRoute} />
          <Route path="/artists/" exact strict component={ArtistsRoute} />
          <Route path="/artists/:id/" exact strict component={ArtistRoute} />
          <Route path="/collections/" exact strict component={CollectionsRoute} />
          <Route path="/collections/:id/" exact strict component={CollectionRoute} />
          <Route path="/clips/" exact strict component={ClipsRoute} />
          <Route path="/clips/:id/" exact strict component={ClipRoute} />
          <Route path="/live/" exact strict component={LivesRoute} />
          <Route path="/live/:id/" exact strict component={LiveRoute} />
          <Route path="/radio/" exact strict component={RadioRoute} />
          <Route path="/search/" exact strict component={SearchRoute} />
          <Route path="*" component={NotFoundRoute} />
        </Switch>
        <BottomPlayer />
        <PopupPlayer />
      </Content>
    </Wrapper>
  );
}

export default App;
