import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

function ClosePopupButton(props) {
  const { onClick } = props;

  return (
    <IconButton onClick={onClick}>
      <KeyboardArrowDownIcon fontSize="large" />
    </IconButton>
  );
}

ClosePopupButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ClosePopupButton;
