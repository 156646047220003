import {
  PLAY, PAUSE, CHANGE_TRACK, CHANGE_PLAYLIST,
  ENABLE_REPEAT, ENABLE_REPEAT_ONE, DISABLE_REPEAT,
  UPDATE_TIME, UPDATE_BUFFER, UPDATE_DURATION, UPDATE_VOLUME,
  SHOW_POPUP, HIDE_POPUP, ENABLE_RADIO, DISABLE_RADIO, UPDATE_RADIO_INFO,
} from '../actionTypes';
import { REPEAT, REPEAT_ONE } from '../../config/player';

const initialState = {
  isPopup: false,
  isPlay: false,
  index: -1,
  compilation: '',
  playlist: [],
  repeat: '',
  currentTime: 0,
  progress: 0,
  buffer: 0,
  duration: 0,
  volume: 70,
  radioInfo: {},
  isRadio: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_POPUP:
      return { ...state, isPopup: true };
    case HIDE_POPUP:
      return { ...state, isPopup: false };
    case PLAY:
      return { ...state, isPlay: true };
    case PAUSE:
      return { ...state, isPlay: false };
    case ENABLE_RADIO:
      return {
        ...state,
        isRadio: true,
        index: -1,
        currentTime: 0,
        progress: 0,
        buffer: 0,
        duration: 0,
        playlist: [],
        compilation: '',
      };
    case DISABLE_RADIO:
      return { ...state, isRadio: false, radioInfo: {} };
    case UPDATE_RADIO_INFO:
      return { ...state, radioInfo: { ...payload.radioInfo } };
    case UPDATE_TIME:
      return { ...state, currentTime: payload.currentTime, progress: payload.progress };
    case UPDATE_BUFFER:
      return { ...state, buffer: payload.buffer };
    case UPDATE_DURATION:
      return { ...state, duration: payload.duration };
    case UPDATE_VOLUME:
      return { ...state, volume: payload.volume };
    case CHANGE_TRACK:
      return {
        ...state,
        index: payload.index,
        currentTime: 0,
        progress: 0,
        buffer: 0,
        duration: 0,
      };
    case CHANGE_PLAYLIST:
      return { ...state, compilation: payload.compilation, playlist: payload.tracks.slice() };
    case ENABLE_REPEAT:
      return { ...state, repeat: REPEAT };
    case ENABLE_REPEAT_ONE:
      return { ...state, repeat: REPEAT_ONE };
    case DISABLE_REPEAT:
      return { ...state, repeat: '' };
    default:
      return state;
  }
}
