import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PlayIcon from '@material-ui/icons/PlayArrow';
import YouTube from 'react-youtube';
import CircularProgress from '@material-ui/core/CircularProgress';
import { pause } from '../../../store/actionCreators/player';
import styles from './styles';

class VideoYouTube extends Component {
  constructor(props) {
    super(props);

    const { source } = this.props;

    this.state = {
      showVideo: false,
      previewSource: `https://img.youtube.com/vi/${source}/maxresdefault.jpg`,
    };
  }

  componentWillUnmount() {
    window.youtubeEvent = null;
  }

  handleImageLoad = (event) => {
    const { source } = this.props;

    if (event.target.naturalWidth === 120) {
      this.setState({
        previewSource: `https://img.youtube.com/vi/${source}/hqdefault.jpg`,
      });
    }
  };

  handleShowVideo = () => {
    const { isPlayAudio, pauseDispatch } = this.props;

    if (isPlayAudio) {
      pauseDispatch();
    }

    this.setState({
      showVideo: true,
    });
  };

  handlePlay = () => {
    const { isPlayAudio, pauseDispatch } = this.props;

    if (isPlayAudio) {
      pauseDispatch();
    }
  };

  handleReady = (event) => {
    event.target.playVideo();
    window.youtubeEvent = event.target;
  };

  render() {
    const { classes, source, title } = this.props;
    const { showVideo, previewSource } = this.state;

    const opts = {
      width: '100%',
      height: '100%',
      playerVars: {
        rel: 0,
        start: 0,
        showinfo: 0,
        autohide: 1,
        wmode: 'opaque',
        iv_load_policy: 3,
        autoplay: 1,
      },
    };

    return (
      <div className={classes.root}>
        <div
          className={classes.block}
          onClick={this.handleShowVideo}
          role="button"
          tabIndex="0"
          onKeyPress={this.handleShowVideo}
        >
          <div className={classes.content} tabIndex="-1">
            <img
              className={classes.image}
              src={previewSource}
              alt={title}
              onLoad={this.handleImageLoad}
            />
            <div className={classes.shadow}>
              <div className={classes.buttonPlay}>
                {showVideo && <CircularProgress className={classes.circularProgress} size={24} />}
                {!showVideo && <PlayIcon className={classes.playIcon} fontSize="large" />}
              </div>
            </div>
            {showVideo && (
              <YouTube
                videoId={source}
                opts={opts}
                onPlay={this.handlePlay}
                onReady={this.handleReady}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

VideoYouTube.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    content: PropTypes.string,
    image: PropTypes.string,
    block: PropTypes.string,
    shadow: PropTypes.string,
    buttonPlay: PropTypes.string,
    playIcon: PropTypes.string,
    circularProgress: PropTypes.string,
  }).isRequired,
  source: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // from store
  isPlayAudio: PropTypes.bool.isRequired,
  pauseDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ isPlayAudio: state.player.isPlay });

const mapDispatchToProps = {
  pauseDispatch: pause,
};

export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps,
)(VideoYouTube));
