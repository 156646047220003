import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

function TrackImage(props) {
  const { classes, src } = props;

  return (
    <div className={classes.root}>
      <img src={src} alt="" className={classes.img} />
    </div>
  );
}

TrackImage.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    img: PropTypes.string,
  }).isRequired,
  src: PropTypes.string.isRequired,
};

export default withStyles(styles)(TrackImage);
