import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import styles from './styles';

function Progress(props) {
  const { classes, value, buffer } = props;

  return (
    <LinearProgress
      className={classes.root}
      variant="buffer"
      value={value}
      valueBuffer={buffer}
      classes={{
        dashed: classes.dashed,
        colorPrimary: classes.linearColorPrimary,
        barColorPrimary: classes.linearBarColorPrimary,
      }}
    />
  );
}

Progress.defaultProps = {
  buffer: 0,
};

Progress.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    dashed: PropTypes.string,
    linearColorPrimary: PropTypes.string,
    linearBarColorPrimary: PropTypes.string,
  }).isRequired,
  value: PropTypes.number.isRequired,
  buffer: PropTypes.number,
};

export default withStyles(styles)(Progress);
