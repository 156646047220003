import React, { Component } from 'react';
import store from './store/store';
import { updateVolume } from './store/actionCreators/player';
import getVolumeFromStorage from './player/helpers/getVolumeFromStorage';
import setVolume from './player/helpers/setVolume';
import App from './App';

class Main extends Component {
  componentDidMount() {
    const jssStyles = document.getElementById('jss-server-side');

    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }

    const volume = getVolumeFromStorage();
    const volumeStore = store.getState().player.volume;

    if (volume) {
      store.dispatch(updateVolume(volume));
    } else {
      setVolume(volumeStore);
    }
  }

  render() {
    return (<App />);
  }
}

export default Main;
