import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Album from '../Album';

function Albums(props) {
  const { items, to } = props;

  return (
    <Grid container spacing={2}>
      {items.map(item => (
        <Grid item xs={4} sm={3} md={2} key={item.id}>
          <Album name={item.name} image={item.image} to={`${to}${item.id}/`} />
        </Grid>
      ))}
    </Grid>
  );
}

Albums.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    image: PropTypes.string,
  })).isRequired,
  to: PropTypes.string.isRequired,
};

export default Albums;
