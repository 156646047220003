import store from '../../store/store';
import { play, pause } from '../../store/actionCreators/player';

function handlePlayToggle() {
  const { player } = store.getState();

  if (player.isPlay) {
    store.dispatch(pause());
  } else {
    store.dispatch(play());
  }
}

export default handlePlayToggle;
