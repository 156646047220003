import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {
  Title, Artists, Text, ContainerBlock,
} from '../../components';
import { API_URL } from '../../config/params';
import { getPreloadedProps, request } from '../../helpers';

class ArtistsRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      ...getPreloadedProps('artists', {
        pageCount: 0,
        currentPage: 0,
        artists: [],
        seo: {},
      }),
    };
  }

  componentDidMount() {
    const { loaded } = this.state;
    const { pgn } = this.props;

    if (!loaded) {
      this.loadData(pgn);
    }
  }

  loadData = async (page) => {
    try {
      this.setState({ loading: true });

      const data = await request(`${API_URL}artists/?page=${page}&seo=1`);

      this.setState({
        data: { ...data },
        loading: false,
        loaded: true,
      });

      document.title = data.seo.title;
    } catch (e) {
      console.log(e.message);
    }
  };

  render() {
    const {
      loading, loaded, data, data: { seo },
    } = this.state;
    const { pgn } = this.props;

    return (
      <ContainerBlock loading={loading} loaded={loaded}>
        <Title>Исполнители</Title>
        <Artists items={data.artists} pageCount={data.pageCount} activePage={pgn} />
        <Text caption={seo.caption} text={seo.text} />
      </ContainerBlock>
    );
  }
}

ArtistsRoute.propTypes = {
  pgn: PropTypes.number.isRequired,
};

const ArtistsRouteWrapper = (props) => {
  const { location: { search } } = props;
  const params = queryString.parse(search);
  const pgn = +(params.pgn ? params.pgn : 1);
  return <ArtistsRoute key={pgn} pgn={pgn} {...props} />;
};

ArtistsRouteWrapper.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default ArtistsRouteWrapper;
