import { isLocalStorage } from '../../helpers';
import { VOLUME_KEY } from '../../config/params';

function getVolumeFromStorage() {
  let volume = null;

  if (isLocalStorage()) {
    volume = parseInt(localStorage.getItem(VOLUME_KEY), 10);
  }

  return Number.isFinite(volume) && volume >= 0 && volume <= 100 ? volume : null;
}

export default getVolumeFromStorage;
