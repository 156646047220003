import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

function Status({ code, children }) {
  return (
    <Route
      render={({ staticContext }) => {
        const context = staticContext;

        if (context) {
          context.status = code;
        }

        return children;
      }}
    />
  );
}

Status.propTypes = {
  code: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
};

export default Status;
