import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import reducer from './reducers/index';
import { player, api } from './middlewares';
import { isClient } from '../helpers';

const store = isClient() ? createStore(
  reducer,
  global.preloadedState,
  composeWithDevTools(applyMiddleware(player, api)),
) : {};

delete global.preloadedState;

export default store;
