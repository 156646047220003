import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

class YandexRTB extends React.Component {
  componentDidMount() {
    const { blockId, pageNumber, type, platform } = this.props;
    const renderTo = `yandex_rtb_${blockId}-${pageNumber}`;

    if (!window) return;

    if (type && platform) {
      window.yaContextCb.push(() => {
        window.Ya.Context.AdvManager.render({
          blockId,
          type,
          platform
        });
      });
    } else {
      window.yaContextCb.push(() => {
        window.Ya.Context.AdvManager.render({
          renderTo,
          blockId,
        });
      });
    }
  }

  render() {
    const { blockId, style, pageNumber } = this.props;
    const idAttr = `yandex_rtb_${blockId}-${pageNumber}`;

    return (<div id={idAttr} style={style} />);
  }
}

YandexRTB.propTypes = {
  style: PropTypes.object, // eslint-disable-line
  blockId: PropTypes.string.isRequired,
  pageNumber: PropTypes.number.isRequired,
  type: PropTypes.string,
  platform: PropTypes.string,
};

export default withRouter(YandexRTB);
