export default theme => ({
  root: {
    display: 'block',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover $image:before': {
      [theme.breakpoints.up('lg')]: {
        display: 'block',
      },
    },
  },
  image: {
    overflow: 'hidden',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    '& img': {
      display: 'block',
      width: '100%',
      maxWidth: '100%',
      height: 'auto',
    },
    '&:before': {
      display: 'none',
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: '#673ab7',
      opacity: 0.4,
    },
  },
});
