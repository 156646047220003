import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import Main from './Main';
import 'whatwg-fetch';
import './bootstrap';
import store from './store/store';
import theme from './config/theme';

const render = (process.env.NODE_ENV === 'production') ? ReactDOM.hydrate : ReactDOM.render;

render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
);
