import React, { useEffect } from 'react';
// import AdSense from 'react-adsense';
import PropTypes from 'prop-types';
import { Container, Section, WaitingBlock, YandexRTB } from '../..';
import { getPageNumber, increasePageNumber } from '../../../helpers';

// function ContainerBlock(props) {
//   const {
//     loading, loaded, children, found,
//   } = props;
//
//   return (
//     <Container fixed={false}>
//       <Section>
//         {
//           process.env.REACT_APP_FLAG === 'production' && (
//             <AdSense.Google
//               client="ca-pub-9923314911785131"
//               slot="1539182382"
//               style={{
//                 display: 'block',
//                 width: '100%',
//                 height: '90px',
//                 marginBottom: '20px',
//               }}
//               format=""
//             />
//           )
//         }
//         <WaitingBlock loading={loading} loaded={loaded} found={found}>
//           {children}
//         </WaitingBlock>
//         {
//           process.env.REACT_APP_FLAG === 'production' && (
//             <AdSense.Google
//               client="ca-pub-9923314911785131"
//               slot="8280560998"
//               style={{ display: 'block', marginTop: '20px' }}
//               format="auto"
//               responsive="true"
//             />
//           )
//         }
//       </Section>
//     </Container>
//   );
// }

function ContainerBlock(props) {
  const {
    loading, loaded, children, found,
  } = props;

  const pageNumber = getPageNumber();

  useEffect(() => {
    if (loaded) {
      increasePageNumber();
    }
  });

  return (
    <Container fixed={false}>
      <Section>
        {
          process.env.REACT_APP_FLAG === 'production' && (
            <YandexRTB
              blockId="R-A-244061-11"
              style={{ marginBottom: '20px' }}
              pageNumber={pageNumber}
            />
          )
        }
        <WaitingBlock loading={loading} loaded={loaded} found={found}>
          {children}
        </WaitingBlock>
        {
          process.env.REACT_APP_FLAG === 'production' && (
            <YandexRTB
              blockId="R-A-244061-10"
              style={{ marginTop: '20px' }}
              pageNumber={pageNumber}
            />
          )
        }
        {
          process.env.REACT_APP_FLAG === 'production' && (
            <YandexRTB
              blockId="R-A-244061-12"
              pageNumber={pageNumber}
              type="fullscreen"
              platform="touch"
            />
          )
        }
      </Section>
    </Container>
  );
}

ContainerBlock.defaultProps = {
  loading: false,
  loaded: true,
  found: true,
};

ContainerBlock.propTypes = {
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  found: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default ContainerBlock;
