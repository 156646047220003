import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Artist from '../Artist';
import Pagination from '../../other/Pagination';
import { LINKS } from '../../../config/params';

function Artists(props) {
  const { items, pageCount, activePage } = props;

  return (
    <>
      <Grid container spacing={2}>
        {items.map(item => (
          <Grid item xs={4} sm={3} md={2} key={item.id}>
            <Artist name={item.name} image={item.image} to={`${LINKS.artist}${item.id}/`} />
          </Grid>
        ))}
      </Grid>
      {!!pageCount && (
        <Pagination pageCount={pageCount} activePage={activePage} path={`${LINKS.artist}?pgn=`} />
      )}
    </>
  );
}

Artists.defaultProps = {
  pageCount: 0,
  activePage: 1,
  items: [],
};

Artists.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    image: PropTypes.string,
  })),
  pageCount: PropTypes.number,
  activePage: PropTypes.number,
};

export default Artists;
