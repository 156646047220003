import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styles from './styles';

function CustomTabs(props) {
  const {
    classes, labels, value, onChange,
  } = props;

  return (
    <Tabs className={classes.root} value={value} onChange={onChange}>
      {
        labels.map(item => <Tab label={item} key={item} />)
      }
    </Tabs>
  );
}

CustomTabs.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(CustomTabs);
