import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import { handleSkipPrevious } from '../../../player/handlers';

function PrevButton(props) {
  const { fontSize } = props;

  return (
    <IconButton onClick={handleSkipPrevious}>
      {<SkipPreviousIcon fontSize={fontSize} />}
    </IconButton>
  );
}

PrevButton.defaultProps = {
  fontSize: 'default',
};

PrevButton.propTypes = {
  fontSize: PropTypes.string,
};

export default PrevButton;
