import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CustomDivider from '../../other/CustomDivider';
import styles from './styles';

function Text(props) {
  const { caption, text, classes } = props;

  if (!caption && !text) {
    return <></>;
  }

  return (
    <>
      <CustomDivider />
      {caption && (
        <Typography
          variant="subtitle2"
          variantMapping={{ subtitle2: 'h1' }}
          classes={{ caption: classes.caption }}
        >
          {caption}
        </Typography>
      )}
      {text && (
        <Typography
          variant="body2"
          variantMapping={{ body2: 'div' }}
          align="justify"
        >
          <div className={classes.content} dangerouslySetInnerHTML={{ __html: text }} />
        </Typography>
      )}
    </>
  );
}

Text.defaultProps = {
  caption: '',
  text: '',
};

Text.propTypes = {
  classes: PropTypes.shape({
    caption: PropTypes.string,
  }).isRequired,
  caption: PropTypes.string,
  text: PropTypes.string,
};

export default withStyles(styles)(Text);
