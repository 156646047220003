import { isClient, initPageNumber } from './helpers';

initPageNumber();

if (isClient() && typeof global === 'undefined') {
  window.global = window;
}

if (!global.preloadedProps) {
  global.preloadedProps = {};
}

if (!global.preloadedState) {
  global.preloadedState = {};
}
