import React, { Component } from 'react';
import { Title, RadioInfoWrapper, Text, ContainerBlock } from '../../components';
import { API_URL } from '../../config/params';
import { getPreloadedProps, request } from '../../helpers';

class RadioRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      ...getPreloadedProps('radio', {
        seo: {},
      }),
    };
  }

  componentDidMount() {
    const { loaded } = this.state;

    if (!loaded) {
      this.loadData();
    }
  }

  loadData = async () => {
    try {
      this.setState({ loading: true });

      const data = await request(`${API_URL}radio?seo=1`);

      this.setState({
        data: { ...data },
        loading: false,
        loaded: true,
      });

      document.title = data.seo.title;
    } catch (e) {
      console.log(e.message);
    }
  };

  render() {
    const { loading, loaded, data: { seo } } = this.state;

    return (
      <ContainerBlock loading={loading} loaded={loaded}>
        <Title>Чувашское онлайн радио</Title>
        <RadioInfoWrapper />
        <Text caption={seo.caption} text={seo.text} />
      </ContainerBlock>
    );
  }
}

export default RadioRoute;
