export default theme => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#fff',
    zIndex: theme.zIndex.modal,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    alignContent: 'center',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(2),
  },
  block: {
    overflowY: 'auto',
  },
  close: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    textAlign: 'center',
  },
  image: {
    marginBottom: theme.spacing(2),
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
    borderRadius: theme.shape.borderRadius,
    margin: 'auto',
  },
  caption: {
    textAlign: 'center',
    marginBottom: theme.spacing(5),
  },
  progressSlider: {
    touchAction: 'none',
    fontSize: 0,
    flexShrink: 0,
    position: 'relative',
  },
  time: {
    ...theme.typography.caption,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(5),
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(8),
  },
  repeat: {
    marginRight: 'auto',
    marginLeft: -theme.spacing(2),
  },
  track: {
    display: 'flex',
    alignItems: 'center',
  },
  play: {
    margin: [[0, theme.spacing(1)]],
  },
});
