import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { handlePlayToggle } from '../../../player/handlers';
import styles from './styles';

function FabPlayButton(props) {
  const { isPlay, classes } = props;

  return (
    <Fab onClick={handlePlayToggle} color="secondary" classes={{ root: classes.root }}>
      {!isPlay ? <PlayIcon fontSize="large" /> : <PauseIcon fontSize="large" />}
    </Fab>
  );
}

FabPlayButton.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
  isPlay: PropTypes.bool.isRequired,
};

export default withStyles(styles)(FabPlayButton);
