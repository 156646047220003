import { START, SUCCESS, FAIL } from '../actionTypes';
import { request } from '../../helpers';

export default () => next => async (action) => {
  const { callAPI, type, ...rest } = action;

  if (!callAPI) {
    next(action);
    return;
  }

  next({ ...rest, type: type + START });

  try {
    const data = await request(callAPI);

    if (data.seo && data.seo.title) {
      global.document.title = data.seo.title;
    }

    next({ ...rest, type: type + SUCCESS, data });
  } catch (error) {
    next({ ...rest, type: type + FAIL, error });
  }
};
