import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

function Title(props) {
  const { classes, children, tag } = props;

  return (
    <Typography variant="h6" variantMapping={{ h6: tag }} className={classes.root}>{children}</Typography>
  );
}

Title.defaultProps = {
  tag: 'div',
};

Title.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
  children: PropTypes.string.isRequired,
  tag: PropTypes.string,
};

export default withStyles(styles)(Title);
