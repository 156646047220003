import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { connect } from 'react-redux';
import { TrackMenu, Container, Caption } from '../../index';
import {
  RepeatButton, ProgressSlider, ClosePopupButton, PrevButton, NextButton, FabPlayButton,
} from '../../player';
import { hidePopup } from '../../../store/actionCreators/player';
import styles from './styles';
import { formatTime } from '../../../helpers';

function PopupPlayer(props) {
  const {
    classes, currentTime, progress, duration, isPopup, isPlay,
    repeat, hidePopupDispatch, playlist, index,
  } = props;

  if (!playlist.length) {
    return <></>;
  }

  const formatCurrentTime = formatTime(currentTime);
  const formatDuration = formatTime(duration);

  const { title, artists, version } = playlist[index];
  const image = artists[0].openGraphImage ? artists[0].openGraphImage : artists[0].image;

  return (
    <Slide direction="up" in={isPopup} mountOnEnter unmountOnExit>
      <div className={classes.root}>
        <div className={classes.block}>
          <Container>
            <>
              <div className={classes.close}>
                <ClosePopupButton onClick={hidePopupDispatch} />
              </div>
              <div className={classes.image}>
                <img src={image} alt="" className={classes.img} />
              </div>
              <div className={classes.caption}>
                <Caption ellipsis={false} title={title} artists={artists} version={version} />
              </div>
              <div className={classes.progressSlider}>
                <ProgressSlider progress={progress} />
              </div>
              <div className={classes.time}>
                <span>{formatCurrentTime}</span>
                <span>{formatDuration}</span>
              </div>
              <div className={classes.buttons}>
                <div className={classes.repeat}>
                  <RepeatButton repeat={repeat} />
                </div>
                <PrevButton fontSize="large" />
                <div className={classes.play}>
                  <FabPlayButton isPlay={isPlay} />
                </div>
                <NextButton fontSize="large" />
                <TrackMenu artists={artists} />
              </div>
            </>
          </Container>
        </div>
      </div>
    </Slide>
  );
}

PopupPlayer.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    block: PropTypes.string,
    close: PropTypes.string,
    image: PropTypes.string,
    img: PropTypes.string,
    caption: PropTypes.string,
    progressSlider: PropTypes.string,
    time: PropTypes.string,
    buttons: PropTypes.string,
    repeat: PropTypes.string,
    track: PropTypes.string,
    play: PropTypes.string,
  }).isRequired,
  // from store
  isPopup: PropTypes.bool.isRequired,
  isPlay: PropTypes.bool.isRequired,
  repeat: PropTypes.string.isRequired,
  playlist: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    version: PropTypes.string,
    artists: PropTypes.array,
  })).isRequired,
  index: PropTypes.number.isRequired,
  currentTime: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  hidePopupDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ ...state.player });

const mapDispatchToProps = { hidePopupDispatch: hidePopup };

export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps,
)(PopupPlayer));
