import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import loadCollections from '../../store/actionCreators/collections';
import {
  Albums, Title, Text, ContainerBlock,
} from '../../components';
import { LINKS } from '../../config/params';

class CollectionsRoute extends Component {
  componentDidMount() {
    const { loaded, loadCollectionsDispatch, seo } = this.props;

    if (!loaded) {
      loadCollectionsDispatch();
    }

    if (seo.title) {
      global.document.title = seo.title;
    }
  }

  render() {
    const {
      loading, loaded, items, seo,
    } = this.props;

    return (
      <ContainerBlock loading={loading} loaded={loaded}>
        <Title>Сборники</Title>
        <Albums items={items} to={LINKS.collection} />
        <Text caption={seo.caption} text={seo.text} />
      </ContainerBlock>
    );
  }
}

CollectionsRoute.propTypes = {
  // from store
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  seo: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  loadCollectionsDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ ...state.collections });

const mapDispatchToProps = { loadCollectionsDispatch: loadCollections };

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsRoute);
