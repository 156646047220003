import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import styles from './styles';

function Pagination(props) {
  const {
    classes, pageCount, activePage, path, pages,
  } = props;

  let links = [];

  if (pages.length > 0) {
    links = pages;
  } else {
    for (let i = 1; i <= pageCount; i += 1) {
      links.push(i);
    }
  }

  if (links.length < 2) {
    return <></>;
  }

  const className = pages.length > 0 ? `${classes.root} ${classes.top}` : classes.root;

  return (
    <div className={className}>
      {links.map((item) => {
        const to = path + item;
        const color = activePage === item ? 'secondary' : 'primary';
        return (
          <NavLink className={classes.link} to={to} key={item}>
            <Button className={classes.button} color={color} size="small" variant="outlined">
              {item}
            </Button>
          </NavLink>
        );
      })}
    </div>
  );
}

Pagination.defaultProps = {
  pages: [],
};

Pagination.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    top: PropTypes.string,
    link: PropTypes.string,
    button: PropTypes.string,
  }).isRequired,
  pageCount: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  pages: PropTypes.arrayOf(PropTypes.number),
};

export default withStyles(styles)(Pagination);
