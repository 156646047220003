import { API_URL } from '../config/params';
import request from './request';

async function getTrack(id) {
  const data = await request(`${API_URL}tracks/${id}`);

  if (!data.id) {
    throw new Error('Track is not found');
  }

  return data;
}

export default getTrack;
