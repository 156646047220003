const drawerWidth = 240;

export default theme => ({
  root: {
    width: drawerWidth,
    flexShrink: 0,
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
});
