import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {
  Title, Lives, Text, ContainerBlock,
} from '../../components';
import { API_URL } from '../../config/params';
import { getPreloadedProps, request } from '../../helpers';

class LivesRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      ...getPreloadedProps('lives', {
        pageCount: 0,
        currentPage: 0,
        lives: [],
        seo: {},
      }),
    };
  }

  componentDidMount() {
    const { loaded } = this.state;
    const { pgn, year } = this.props;

    if (!loaded) {
      this.loadData(year, pgn);
    }
  }

  loadData = async (year, page) => {
    try {
      this.setState({ loading: true });

      let url = '';

      if (year > 0) {
        url = `${API_URL}lives/?year=${year}&page=${page}&seo=1`;
      } else {
        url = `${API_URL}lives/?page=${page}&seo=1`;
      }

      const data = await request(url);

      this.setState({
        data: { ...data },
        loading: false,
        loaded: true,
      });

      document.title = data.seo.title;
    } catch (e) {
      console.log(e.message);
    }
  };

  render() {
    const {
      loading,
      loaded,
      data: {
        years, lives, pageCount, seo,
      },
    } = this.state;
    const { year, pgn } = this.props;

    return (
      <ContainerBlock loading={loading} loaded={loaded}>
        <Title>Live</Title>
        <Lives
          items={lives}
          years={years}
          year={year}
          pageCount={pageCount}
          activePage={pgn}
        />
        <Text caption={seo.caption} text={seo.text} />
      </ContainerBlock>
    );
  }
}

LivesRoute.propTypes = {
  pgn: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
};

const LivesRouteWrapper = (props) => {
  const { location: { search } } = props;
  const params = queryString.parse(search);
  const pgn = +(params.pgn ? params.pgn : 1);
  const year = +(params.year ? params.year : 0);
  return <LivesRoute key={pgn + year} pgn={pgn} year={year} {...props} />;
};

LivesRouteWrapper.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default LivesRouteWrapper;
