export default theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '&:hover $slider': {
      display: 'flex',
    },
  },
  slider: {
    display: 'none',
    width: 200,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    cursor: 'pointer',
  },
  button: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});
